@font-face {
  font-family: silkScreen;
  src: url(../public/Silkscreen-Bold.ttf);
}

.App {
  text-align: center;
}

.auth {
  width: 60%;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 70px;
  border-width: 3px;
  border-color: #2FEA7A;
  border-style: solid;
  border-radius: 10px;
}

.auth-contain {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  background-color: #181A1C;
  background-image: url(../public/bkgd.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: #ffffff;
  font-size: 18px;
  margin: 0 auto;
}

h1 {
  margin: 0 auto;
  font-family: silkScreen;
}

.date {
  width: 100%;
  text-align: center;
  font-size: 16px;
}

#date {
  font-family: silkScreen;
  font-weight: bolder;
  font-size: 14px;
  color: white;
}

.warning {
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #2FEA7A;
  margin-bottom: 25px;
  position: fixed;
  bottom: 0%;
  letter-spacing: normal;
}

.warning-top {
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin-bottom: 25px;
  color: #2FEA7A;
}


